/*## GENERAL FIXES FOR ALL PAGES #####################################################################*/

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
    float: left;
    padding-left: 0px;
    padding-right: 0px;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12 {
    float: left;
    padding-left: 12px;
    padding-right: 12px;
}

.header-toolbar > .container-fluid > .row {
    padding-left: 12px;
    padding-right: 12px;

    > .col:first-child {
        width: 80%;
    }

    > .dropdown {
        float: right;
    }
}
.d-flex,
.container-fluid {
    display: block;
}
ol.breadcrumb {
    padding: 0 12px;
}

.row:not(.ie-fluid) {
    display: inline-block;
    width: 100%;
    margin: 0;
}

.productdetail_info_right_actions .row {
    overflow: hidden;
    display: block;
}

.btn {
    height: 40px;
}
.section {
    overflow: hidden;
}

.ml-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.nav > *,
.input-group > *,
.pagination > *,
.d-flex > * {
    display: inline-block;
}

/*fix needed for: left navigation is responsive but not the right content-part(with .webkit class)*/
.webkit {
    clear: none !important;
}

script {
    display: none !important;
}

/*## PAGE SPECIFIC FIXES ##############################################################################*/

.header {
    .header-search {
        .header-brand-logo,
        .header-brand-logo img {
            max-height: 40px;
        }

        .header-search-group {
            > div {
                &.col,
                &.col-auto {
                    display: inline-block;
                    float: left;
                    width: 65%;

                    &.header-brand-logo {
                        width: 15%;
                    }
                    &#headerToolbarRequisitionPreview {
                        width: 20%;
                    }
                }

                input {
                    height: auto !important;
                }
            }

            .cart-preview {
                .cart-preview-item {
                    > a div {
                        float: left;
                        display: block;

                        &.col-3 {
                            width: 25%;
                        }

                        &.col-9 {
                            width: 75%;
                        }
                    }
                }
            }
        }
    }

    .header-nav {
        .navbar {
            .container {
                > .navbar-collapse {
                    display: block !important;

                    .nav-item .nav-link {
                        font-weight: bold;
                    }
                }

                > div.col-auto {
                    float: right;
                    display: block;

                    a.nav-link {
                        font-weight: bold;
                    }
                }

                .header-user-content {
                    > div.col {
                        width: 41.66%;
                        float: left;
                    }
                }

                .header-user-login-wrapper .header-user-content {
                    > div.col {
                        width: 41.6666666667%;

                        @media all and (min-width: 992px) {
                            width: 33.3333333333%;
                        }
                    }
                    .col,
                    .col-1,
                    .col-2,
                    .col-3,
                    .col-4,
                    .col-5,
                    .col-6,
                    .col-7,
                    .col-8,
                    .col-9,
                    .col-10,
                    .col-11,
                    .col-12 {
                        float: left;
                    }
                }
            }
        }

        .navbar .container {
            > .navbar-collapse {
                float: left;

                .navbar-nav {
                    margin-left: 0 !important;
                }
            }
        }
    }

    &.header-new {
        .cart-preview-toggle {
            i.icon-ecommerce-shopcart {
                float: right;
            }
        }
    }
}

.loginForm {
    .heading-alt i.icon-wuerth-information {
        margin-right: 1rem;
    }
    .custom-checkbox .custom-control-indicator {
        top: 2px;
    }
}

.sticky.is-sticky.header-search {
    z-index: 1000;
}
.header-nav {
    .navbar {
        .navbar-center {
            display: inline-block !important;
        }

        .navbar-brand {
            margin-top: 1rem;
        }
        .navbar-collapse {
            vertical-align: middle;
            width: 75%;
            ul.navbar-nav {
                top: 20px;
                margin-left: 45px !important;
                li {
                    float: left;
                }
            }

            @media all and (min-width: 992px) {
                width: 65%;

                ul.navbar-nav {
                    margin-left: 75px !important;
                }
            }

            @media all and (min-width: 1200px) {
                ul.navbar-nav {
                    margin-left: 130px !important;
                }
            }
        }
        .navbar-right {
            float: right;
            position: relative;
            top: 20px;
        }
    }
}

#suggestSearch {
    width: 100%;
}

div.headerNavItems {
    a#topSellerButton {
        display: inline-block !important;
    }

    div.text-right {
        width: auto;
        display: inline-block;
        float: right;
    }
}

/*PDP PDS - quantity control plus/input/minus*/
table .input-group > input.form-control {
    width: 100px;
    display: inline-block;
}

a.icon > i {
    float: left;
    margin-top: 0;
}

.header-toolbar {
    a.icon > i {
        float: none;
    }
}

.btn-quantity-plus,
.btn-quantity-minus {
    float: left;
}
input.input_amount {
    height: 40px;
    float: left;
}

.input-group > * {
    display: block;
    height: 40px;
    float: left;
}
.input-group > .btn-quantity-minus,
.input-group > .btn-quantity-plus {
    width: auto;
    height: 40px;
    float: left;
    display: block;
    line-height: 3em;
}

.input-group > input.input_amount {
    max-width: 50px !important;
    min-width: 30px;
}
.input-quantity a:last-of-type i.input-group-addon,
.input-quantity a:first-of-type i.input-group-addon,
#ModelDetailForm .dataTable > thead tr.filter-row .filter-row-selected-filters .filter-row-selected-filters-left {
    display: block !important;
}
.img-fluid {
    width: auto;
}

.is-sticky.sticky .input-header-search.input-group {
    min-height: 42px;
}
#headerSearch ul.ui-autocomplete,
.search-mobile ul.ui-autocomplete {
    min-height: 300px;
}
.is-sticky.header-add-cart .card-body {
    padding: 1.2rem 0 0 0;
}
.is-sticky.header-add-cart .card-body form .sticky-area-left,
.is-sticky.header-add-cart .card-body form .sticky-area-right {
    display: block;
    float: left;
    width: 50%;
}
.is-sticky.header-add-cart .card-body form .sticky-area-right h3 {
    float: left;
}
.header-add-cart.is-sticky.sticky {
    top: 11rem;
}
.is-sticky.header-add-cart .card-body form .input-quantity,
.is-sticky.header-add-cart .card-body form .sticky-area-right .add-to-cart-unit-col .new-select-dropdown {
    float: right !important;
    width: auto;
    max-width: 120px;
}

.nav-tabs {
    .nav-item {
        &:not(:first-of-type) {
            margin-left: -4px;
        }
    }
}
.productdetail_info_right,
.model_info {
    .col-lg-7,
    .col-lg-5 {
        padding: 0;
        margin: 0;
    }
}
.productDetailLinkedProductsTab .linked-section-header .row .md-description {
    float: right;
}

.slick.slider-reco .slick-track .slick-slide .card .img-wrapper img,
.slick.slider-reco-category .slick-track .slick-slide .card .img-wrapper img,
.slick.slider-reco-delayed .slick-track .slick-slide .card .img-wrapper img,
.slick.slider-reco-variant .slick-track .slick-slide .card .img-wrapper img,
.slick.slider-products-teaser .slick-track .slick-slide .card .img-wrapper img {
    position: relative;
    top: 25%;
    left: inherit;
}
.navbar-brand-scroll img,
.navbar-brand img {
    max-height: 26px !important;
}

.ui-autocomplete-item > a {
    min-height: 47px !important;
}
.ui-autocomplete-text.rightColumnSuggestSearch {
    width: 50% !important;
}
.ui-autocomplete-text.leftColumnSuggestSearch span {
    width: 50% !important;
    display: block;
}
